import React, { Component } from "react";
import "./LayerReveal.css";
import layerOne from "../../assets/AboutUs/layers/1.png";
import layerTwo from "../../assets/AboutUs/layers/2.png";
import layerThree from "../../assets/AboutUs/layers/3.png";
import layerFour from "../../assets/AboutUs/layers/4.png";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

class LayerReveal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div id="trigger" />
        <Controller>
          <Scene triggerElement="#trigger" duration={600}>
            {(progress) => (
              <div className="row justify-content-center position-relative layer-reveal">
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img
                    src={layerFour}
                    className="position-absolute layer-four"
                  />
                </Tween>
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img
                    src={layerThree}
                    className="position-absolute layer-three"
                  />
                </Tween>
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img src={layerTwo} className="position-absolute layer-two" />
                </Tween>
                <Tween
                  from={{
                    y: 143,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <img src={layerOne} className="position-absolute layer-one" />
                </Tween>
              </div>
            )}
          </Scene>
        </Controller>
        {/* <img src={layerFour} className="position-absolute layer-four" />
        <img src={layerThree} className="position-absolute layer-three" />
        <img src={layerTwo} className="position-absolute layer-two" />
        <img src={layerOne} className="position-absolute" /> */}
      </div>
    );
  }
}

export default LayerReveal;
