import React, { Component } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Controls, PlayState, Timeline, Tween } from "react-gsap";
import Lottie from "react-lottie";
import "./HomePage.css";
import FButtonPill from "../../components/FButtonPill/FButtonPill";
import FButton from "../../components/FButton/FButton";
import scrollTwo from "../../assets/HomePage/scroll-2.png";
import scrollThree from "../../assets/HomePage/scroll-3.png";
import scrollFour from "../../assets/HomePage/scroll-4.png";
import isometric from "../../assets/HomePage/isometric.png";
import aeAnim1 from "../../assets/HomePage/1.json";
import aeAnim2 from "../../assets/HomePage/2.json";
import aeAnim3 from "../../assets/HomePage/3.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OnScrollReveal from "../../components/global/Animations/OnScrollReveal";
const pageDataTheme = "dark";

//Adding Icons to Lib
library.add(fab);

const ChangePageColour = (props) => {
  const progress = props.progress;
  if (progress < 0.25)
    return <div className="color-second color-xs">{props.children}</div>;
  else if (progress < 0.5)
    return <div className="color-first color-xs">{props.children}</div>;
  else if (progress < 0.75)
    return <div className="color-third color-xs">{props.children}</div>;
  else return <div className="color-fourth color-xs">{props.children}</div>;
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    document.documentElement.setAttribute("data-theme", "dark");
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // analytics.logEvent("Home did Mount");
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    //for ae animations
    const defaultAnimationOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    var scrollDuration = "";

    if (this.state.height > 700 && this.state.width > 600) {
      scrollDuration = "400%";
    } else {
      scrollDuration = "500%";
    }

    return (
      <div className="overflow-x-hidden">
        <Controller>
          <Scene duration={scrollDuration} triggerHook={0.5}>
            {(progress) => (
              <div className="homepage-scroll-section-master">
                <ChangePageColour progress={progress}>
                  <div className="container homepage-scroll-section">
                    <div className="row justify-content-end">
                      <div className="col-md"></div>
                      <div id="trig1" className="col landing-iso-div">
                        <Tween
                          from={{ y: -170, x: -300 }}
                          duration={3}
                          ease="bounce"
                        >
                          <div>
                            <Timeline
                              repeat={1000}
                              target={
                                <img
                                  className="landing-iso-1"
                                  src={require("../../assets/HomePage/landing1.svg")}
                                />
                              }
                            >
                              <Tween to={{ y: 190 }} ease="none" duration={8} />
                              <Tween to={{ y: 215 }} ease="none" duration={8} />
                            </Timeline>
                          </div>
                        </Tween>
                        <Tween
                          from={{ y: 100, x: 175 }}
                          duration={3}
                          ease="bounce"
                        >
                          <div>
                            <Timeline
                              repeat={1000}
                              target={
                                <img
                                  className="landing-iso-2"
                                  src={require("../../assets/HomePage/landing2.svg")}
                                />
                              }
                            >
                              <Tween to={{ y: 25 }} ease="none" duration={8} />
                              <Tween to={{ y: 0 }} ease="none" duration={8} />
                            </Timeline>
                          </div>
                        </Tween>
                      </div>
                    </div>
                    <div className="row justify-content-start homepage-landing-text">
                      <div className="col text-center text-md-left">
                        <h1 className="f-header">We are Flavus</h1>
                        <p>A student-built media agency. Made, for you.</p>
                      </div>
                    </div>
                  </div>
                </ChangePageColour>
                <ChangePageColour progress={progress}>
                  <div className="container homepage-scroll-section mt-n5 mt-md-0">
                    <div className="row justify-content-center justify-content-md-between align-items-center">
                      <div className="col-md-6 text-left">
                        <OnScrollReveal y={100} duration={2}>
                          <div className="row">
                            <div className="float-left">
                              <Lottie
                                options={{
                                  ...defaultAnimationOptions,
                                  ...{ animationData: aeAnim1 },
                                }}
                                height={120}
                                width={120}
                              />
                            </div>
                          </div>
                          <OnScrollReveal y={50} duration={0.25}>
                            <h3 className="homepage-scroll-title">
                              Flavus media at your service
                            </h3>
                            <OnScrollReveal y={50} duration={0.75}>
                              <h4 className="homepage-scroll-subtitle mt">
                                Giving you the most elegant designs on the
                                market
                              </h4>
                              <OnScrollReveal y={50} duration={1.25}>
                                <p className="homepage-scroll-desc">
                                  Our design team is capabale of blowing your
                                  mind away, making the most intricate, yet
                                  simple and captivating designs.
                                </p>
                              </OnScrollReveal>
                            </OnScrollReveal>
                          </OnScrollReveal>
                        </OnScrollReveal>
                      </div>
                      <div className="col-md-5 col-8 mt-5 mt-md-0">
                        <img src={scrollTwo} className="col" />
                      </div>
                    </div>
                  </div>
                </ChangePageColour>
                <ChangePageColour progress={progress}>
                  <div className="container homepage-scroll-section ">
                    <div className="row justify-content-center justify-content-md-between align-items-center">
                      <div className="col-md-5 col-8 order-2 order-md-1 mt-5 mt-md-0">
                        <img src={scrollThree} className="col" />
                      </div>
                      <div className="col-md-6 text-right order-1 order-md-2">
                        <OnScrollReveal y={100} duration={2}>
                          <div className="row justify-content-end">
                            <div className="float-right">
                              <Lottie
                                options={{
                                  ...defaultAnimationOptions,
                                  ...{ animationData: aeAnim2 },
                                }}
                                height={120}
                                width={120}
                              />
                            </div>
                          </div>
                          <OnScrollReveal y={50} duration={0.25}>
                            <h3 className="homepage-scroll-title">
                              Flavus media for you
                            </h3>
                            <OnScrollReveal y={50} duration={0.75}>
                              <h4 className="homepage-scroll-subtitle">
                                Make our designs, your designs
                              </h4>
                              <OnScrollReveal y={50} duration={1.25}>
                                <p className="homepage-scroll-desc">
                                  Customize, personalize, and cherish your
                                  awesome design with Flavus Media, where we
                                  create all your needs with passion.
                                </p>
                              </OnScrollReveal>
                            </OnScrollReveal>
                          </OnScrollReveal>
                        </OnScrollReveal>
                      </div>
                    </div>
                  </div>
                </ChangePageColour>
                <ChangePageColour progress={progress}>
                  <div className="container homepage-scroll-section color-dark">
                    <div className="row justify-content-center justify-content-md-between align-items-center">
                      <div className="col-md-6 text-left">
                        <OnScrollReveal y={100} duration={2}>
                          <div className="row">
                            <div className="float-left">
                              <Lottie
                                options={{
                                  ...defaultAnimationOptions,
                                  ...{ animationData: aeAnim3 },
                                }}
                                height={120}
                                width={120}
                              />
                            </div>
                          </div>
                          <OnScrollReveal y={50} duration={0.25}>
                            <h3 className="homepage-scroll-title">
                              Flavus media to the rescue
                            </h3>
                            <OnScrollReveal y={50} duration={0.75}>
                              <h4 className="homepage-scroll-subtitle">
                                We've got you covered
                              </h4>
                              <OnScrollReveal y={50} duration={1.25}>
                                <p className="homepage-scroll-desc">
                                  Whether it be web illustrations, an app UI, or
                                  a simple print design, Flavus Media will
                                  always be in reach to help you get the media
                                  you need.
                                </p>
                              </OnScrollReveal>
                            </OnScrollReveal>
                          </OnScrollReveal>
                        </OnScrollReveal>
                      </div>
                      <div className="col-md-4 col-7 mt-5 mt-md-0">
                        <img src={scrollFour} className="col" />
                      </div>
                    </div>
                  </div>
                </ChangePageColour>
              </div>
            )}
          </Scene>
        </Controller>
        <div className="landing-overlay position-relative">
          {/* Background Logo Rotating */}
          <div className="bg-logo-1">
            <img
              className="position-absolute bg-logos"
              src={require("../../assets/HomePage/f-logo-outline.svg")}
            />
          </div>
          <div className="bg-logo-2">
            <img
              className="position-absolute bg-logos"
              src={require("../../assets/HomePage/f-logo-outline.svg")}
            />
          </div>
          <div className="bg-logo-3">
            <img
              className="position-absolute bg-logos"
              src={require("../../assets/HomePage/f-logo-outline.svg")}
            />
          </div>
          <div className="bg-logo-4">
            <img
              className="position-absolute bg-logos"
              src={require("../../assets/HomePage/f-logo-outline.svg")}
            />
          </div>
          <div className="bg-logo-5">
            <img
              className="position-absolute bg-logos"
              src={require("../../assets/HomePage/f-logo-outline.svg")}
            />
          </div>

          <OnScrollReveal y={50} duration={3} opacity={1}>
            <div className="container landing ">
              <div className="row justify-content-between align-items-center landing-row">
                <div className="col-md-6">
                  <img
                    src={isometric}
                    className="col ml-md-n5 landing-newDesign-img"
                  />
                </div>
                <div className="col-md-6 mt-n5 mt-md-0">
                  <OnScrollReveal y={50} duration={0.5}>
                    <h1 className="f-header landing-title">
                      Your new design starts here
                    </h1>
                    <OnScrollReveal y={50} duration={1.25}>
                      <p className="f-desc">
                        Team up with our vibrant design team at Flavus Media to
                        get your next elegantly crafted set of media.
                      </p>
                      <OnScrollReveal y={50} duration={2}>
                        <div className="row m-0">
                          <FButtonPill
                            value="Learn More"
                            href="about"
                            style="dark"
                          />
                          <div className="ml-3">
                            <FButtonPill
                              value="Contact Us"
                              href="contact"
                              style="darkOnLight"
                            />
                          </div>
                        </div>
                      </OnScrollReveal>
                    </OnScrollReveal>
                  </OnScrollReveal>
                </div>
              </div>
            </div>
          </OnScrollReveal>
          <OnScrollReveal y={50} duration={3} opacity={1}>
            <div className="container landing padding-b-120 mt-5 mt-md-0">
              <div className="row justify-content-between align-items-center landing-row">
                <div className="col-md-6 order-2 order-md-1 mt-5 mt-md-0">
                  <OnScrollReveal y={50} duration={0.5}>
                    <h1 className="f-header landing-title">
                      The best in-house designers
                    </h1>
                    <OnScrollReveal y={50} duration={1.25}>
                      <p className="f-desc">
                        Our in-house designers have the experience, craft, and
                        skill to pull off some of the mose creative work you’ve
                        ever seen.
                      </p>
                      <div className="row m-0">
                        <FButtonPill
                          value="Our Services"
                          href="services"
                          style="dark"
                        />
                        <div className="ml-3">
                          <FButtonPill
                            value="Our Portfolio"
                            href="portfolio"
                            style="darkOnLight"
                          />
                        </div>
                      </div>
                    </OnScrollReveal>
                  </OnScrollReveal>
                </div>
                <div className="col-md-5 order-1 order-md-2">
                  <img
                    src={require("../../assets/HomePage/house.svg")}
                    className="col"
                  />
                </div>
              </div>
            </div>
          </OnScrollReveal>
        </div>
        <div className="landing-contact text-center ">
          <h3 className="f-subtitle padding-t-70">Like our work?</h3>
          <h2 className="f-title mt-4">Let's get in touch.</h2>
          <a href="mailto:contact@flavus.ca" target="_blank">
            <button className="mail-button mt-4">contact@flavus.ca</button>
          </a>
          <div className="row justify-content-center mt-4">
            <div className="col-md-2 col-7 row justify-content-between ">
              <a href="https://www.instagram.com/flavus.ca/" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  size="md"
                  className="contact-icons"
                />
              </a>
              <a href="https://www.facebook.com/flavus.ca" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "facebook-f"]}
                  size="md"
                  className="contact-icons"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/flavusmedia/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={["fab", "linkedin-in"]}
                  size="md"
                  className="contact-icons"
                />
              </a>
            </div>
          </div>
          <div className="padding-b-70 mt-4">
            <FButtonPill value="Contact Us" href="contact" style="light" />
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
