import React, { Component } from "react";
import "./Contact.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FButtonPill from "../../components/FButtonPill/FButtonPill";
import { Controls, PlayState, Timeline, Tween } from "react-gsap";
import ContactForm from "./ContactForm";

library.add(fab);

class Contact extends Component {
  constructor(props) {
    super(props);
    document.documentElement.setAttribute("data-theme", "dark");
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    var formWidth = "";

    if (this.state.width > 599) {
      formWidth = "640";
    } else {
      formWidth = "370";
    }

    return (
      <div className="contact-header text-center padding-t-120 overflow-x-hidden">
        <h3 className="f-subtitle padding-t-70">Like our work?</h3>
        <h2 className="f-title">Let's get in touch.</h2>
        <a href="mailto:contact@flavus.ca" target="_blank">
          <button className="contact-mail-button mt-4">
            contact@flavus.ca
          </button>
        </a>
        <Tween from={{ x: -300, y: -300 }} duration={2} ease="power1">
          <div>
            <Timeline
              repeat={1000}
              target={<div class="triangle-1C position-absolute"></div>}
            >
              <Tween to={{ y: -65, rotate: 77 }} ease="none" duration={6} />
              <Tween to={{ y: -190, rotate: 122 }} ease="none" duration={12} />
              <Tween to={{ y: -127, rotate: 152 }} ease="none" duration={6} />
            </Timeline>
          </div>
        </Tween>
        <Tween from={{ x: 300, y: -300 }} duration={2.25} ease="power1">
          <div>
            <Timeline
              repeat={1000}
              target={<div class="square-1C position-absolute"></div>}
            >
              <Tween to={{ y: 50, rotate: -36 }} ease="none" duration={6} />
              <Tween to={{ y: -50, rotate: -72 }} ease="none" duration={12} />
              <Tween to={{ y: 0, rotate: -90 }} ease="none" duration={6} />
            </Timeline>
          </div>
        </Tween>
        <Tween from={{ x: 100, y: 50 }} duration={3.25} ease="power1">
          <div>
            <Timeline
              repeat={1000}
              target={<div class="square-2C position-absolute"></div>}
            >
              <Tween to={{ y: 675, rotate: 81 }} ease="none" duration={6} />
              <Tween to={{ y: 625, rotate: 114 }} ease="none" duration={12} />
              <Tween to={{ y: 650, rotate: 135 }} ease="none" duration={6} />
            </Timeline>
          </div>
        </Tween>
        <Tween from={{ x: -100, y: 200 }} duration={2.5} ease="power1">
          <div>
            <Timeline
              repeat={1000}
              target={<div class="circle-1C position-absolute"></div>}
            >
              <Tween to={{ y: 300 }} ease="none" duration={6} />
              <Tween to={{ y: 200 }} ease="none" duration={12} />
              <Tween to={{ y: 250 }} ease="none" duration={6} />
            </Timeline>
          </div>
        </Tween>
        <Tween from={{ x: -100, y: -250 }} duration={2.75} ease="power1">
          <div>
            <Timeline
              repeat={1000}
              target={<div class="circle-2C position-absolute"></div>}
            >
              <Tween to={{ x: "40%", y: 200 }} ease="none" duration={6} />
              <Tween to={{ x: "20%", y: 100 }} ease="none" duration={12} />
              <Tween to={{ x: "30%", y: 150 }} ease="none" duration={6} />
            </Timeline>
          </div>
        </Tween>

        <Tween from={{ x: 100, y: -300 }} duration={3} ease="power1">
          <Timeline
            repeat={1000}
            target={<div class="circle-3C position-absolute"></div>}
          >
            <Tween to={{ x: "-30%", y: 565 }} ease="none" duration={6} />
            <Tween to={{ x: "-50%", y: 485 }} ease="none" duration={12} />
            <Tween to={{ x: "-40%", y: 525 }} ease="none" duration={6} />
          </Timeline>
        </Tween>

        <div className="row justify-content-center mt-4">
          <div className="col-md-2 col-8 row justify-content-between ">
            <a href="https://www.instagram.com/flavus.ca/" target="_blank">
              <FontAwesomeIcon
                icon={["fab", "instagram"]}
                size="md"
                className="contact-page-icons"
              />
            </a>
            <a href="https://www.facebook.com/flavus.ca" target="_blank">
              <FontAwesomeIcon
                icon={["fab", "facebook-f"]}
                size="md"
                className="contact-page-icons"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/flavusmedia/"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={["fab", "linkedin-in"]}
                size="md"
                className="contact-page-icons"
              />
            </a>
          </div>
        </div>
        <div className="row justify-content-center mt-5 padding-b-70">
          <div class="col">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdD7OYnj3x5r7vxsA03slNOx9mbDJjhvGSla_cDe51eAZINaw/viewform?embedded=true"
              width={formWidth}
              height="1240"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>

            {/*<div class="wrap-login100">
              <ContactForm />
    </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
