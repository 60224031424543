import React, { Component } from "react";
import "./MyNavbar.css";
import logo from "../../../assets/logo.svg";
import logoDark from "../../../assets/logo-dark.svg";
import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

console.log(document.documentElement.getAttribute("data-theme"));

class MyNavbar extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="none"
        variant="light"
        fixed="top"
        className="mynavbar py-md-3 px-md-5 px-3 py-2"
      >
        <LinkContainer to="/" className="navbar-logo-container">
          <Navbar.Brand>
            <div className="navbar-logo-light">
              <img src={logo} className="navbar-logo" />
            </div>
            <div className="navbar-logo-dark">
              <img src={logoDark} className="navbar-logo" />
            </div>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="mynavbar-toggle"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className=""></Nav>
          <Nav className="ml-auto">
            <LinkContainer to="/about">
              <Nav.Link className="navbar-link-text">About Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/services">
              <Nav.Link className="navbar-link-text">Our Services</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/portfolio">
              <Nav.Link className="navbar-link-text">Our Portfolio</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link className="navbar-link-text">Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default MyNavbar;
