import React, { Component, useState } from "react";
import "./ServicesCarousel.css";
import ServicesCarouselItem from "../ServicesCarouselItem/ServicesCarouselItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ServicesCarousel extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
          },
        },
      ],
    };
    return (
      <div className="container services-carousel">
        <Slider {...settings}>
          <ServicesCarouselItem
            src={require("../../assets/OurServices/carousel/print.jpg")}
            title="Print Design"
            tag="Illustration"
            desc="Designing posters, flyers, documents, you name it."
            className="pb-4"
          />
          <ServicesCarouselItem
            src={require("../../assets/OurServices/carousel/businessCards.jpg")}
            title="Business Cards"
            tag="Branding Identity"
            desc="Business cards are essential to keeping your image stay clean and professional to the public. Let us give you a hand in crafting your next business card."
          />
          <ServicesCarouselItem
            src={require("../../assets/OurServices/carousel/socialMedia.jpg")}
            title="Social Media Campaigns"
            tag="Social Media"
            desc="Flavus’ Social Media package gives you the most elegant and innovative media content that can be used on multiple social platforms."
          />
          <ServicesCarouselItem
            src={require("../../assets/OurServices/carousel/monthlySocialMedia.jpg")}
            title="Monthly Social Media Campaign"
            tag="Social Media"
            desc="Need someone to count on to make your social media content over a long period of time? Contact us for a montly social media subscription for more details."
          />
        </Slider>
      </div>
    );
  }
}

export default ServicesCarousel;
