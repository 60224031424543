import React, { Component } from "react";
import "./Contact.css";
import FButtonPill from "../../components/FButtonPill/FButtonPill";

class FContactForm extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      number: "",
      email: "",
      message: "",
      con_from: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit(e) {
    e.preventDefault();
    //console.log(this.state);
    //const { name, number, email, message, con_from } = this.state;
  }

  render() {
    return (
      <form class="login100-form" onSubmit={this.handleSubmit}>
        <div class="wrap-input100 mb-5">
          <input
            class="input100"
            type="text"
            placeholder="Name"
            name="name"
            onChange={this.handleChange}
          />
          <span class="focus-input100"></span>
        </div>

        <div class="wrap-input100 mb-5">
          <input
            class="input100"
            type="number"
            placeholder="Phone Number"
            name="number"
            onChange={this.handleChange}
          />
          <span class="focus-input100"></span>
        </div>
        <div class="wrap-input100 mb-5">
          <input
            class="input100"
            type="email"
            placeholder="Email Address"
            name="email"
            onChange={this.handleChange}
          />
          <span class="focus-input100"></span>
        </div>
        <div class="wrap-input100 mb-5">
          <textarea
            class="input100"
            type="textarea"
            placeholder="How can we help you?"
            name="message"
            onChange={this.handleChange}
          />
          <span class="focus-input100"></span>
        </div>
        <div class="text-left radio-buttons-div mb-5">
          <p>How did you hear about us?</p>
          <input
            type="radio"
            id="Instagram"
            name="con_from"
            value="Instagram"
            className="radio-button"
            onChange={this.handleChange}
          />
          <label for="Instagram" className="ml-4">
            Instagram
          </label>
          <br />
          <input
            type="radio"
            id="Facebook"
            name="con_from"
            value="Facebook"
            className="radio-button"
            onChange={this.handleChange}
          />
          <label for="Facebook" className="ml-4">
            Facebook
          </label>
          <br />
          <input
            type="radio"
            id="LinkedIn"
            name="con_from"
            value="LinkedIn"
            className="radio-button"
            onChange={this.handleChange}
          />
          <label for="LinkedIn" className="ml-4">
            LinkedIn
          </label>
          <br />
          <input
            type="radio"
            id="Friend"
            name="con_from"
            value="Friend"
            className="radio-button"
            onChange={this.handleChange}
          />
          <label for="Friend" className="ml-4">
            Friend
          </label>
          <br />

          <input
            type="radio"
            id="Other"
            name="con_from"
            value="Other"
            className="radio-button"
            onChange={this.handleChange}
          />
          <label for="Other" className="ml-4">
            Other
          </label>
          <br />

          <span class="focus-input100"></span>
        </div>

        <FButtonPill
          value="Submit"
          style="light"
          id="con_submit"
          type="submit"
        />
      </form>
    );
  }
}

export default FContactForm;
