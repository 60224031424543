import React, { Component } from "react";
import Lottie from "react-lottie";
import "./Portfolio.css";

import PortfolioItem from "../../components/PortfolioItem/PortfolioItem";
import FButtonPill from "../../components/FButtonPill/FButtonPill";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    document.documentElement.setAttribute("data-theme", "light");
  }

  render() {
    return (
      <div className="overflow-x-hidden">
        <div className="portfolio-header container">
          <div className="row align-items-end text-left h-100">
            <div className="col">
              <h2 className="f-subtitle">Flavus Media Folio</h2>
              <h3 className="f-header">Sharing our best work yet</h3>
            </div>
          </div>
        </div>
        <div className="container-md">
          <div className="margin-t-70 portfolio-banner">
            <div className="row justify-content-center justify-content-md-between mx-5 align-items-center">
              <div className="col text-center text-md-left order-2 order-md-1 mt-3 mt-md-0">
                <h4 className="f-subtitle">Like our work?</h4>
                <h3 className="f-title">Let's get in touch.</h3>
              </div>
              <img
                className="col-12 col-md-4 order-1 order-md-2 pt-4 pt-md-0"
                src={require("../../assets/HomePage/isometric.png")}
              />
              <div className="col-md-auto col-auto order-3 pb-4 pb-md-0 mt-3 mt-md-0">
                <FButtonPill value="Contact Us" style="dark" href="contact" />
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-content margin-t-70 padding-b-70 container">
          <div className="row justify-content-between">
            <div className="col-md">
              <PortfolioItem
                src={require("../../assets/Portfolio/1.png")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/1.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/2.jpg")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/2.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/3.png")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/3.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/4.png")}
                text="UI/UX Concept"
                imgThumb={require("../../assets/Portfolio/thumbnails/4.jpg")}
              />
            </div>
            <div className="col-md">
              <PortfolioItem
                src={require("../../assets/Portfolio/5.png")}
                text="Concept Art"
                buttonText="View Project"
                href="https://www.behance.net/gallery/99210653/Apple-iMac-Pro-Concept"
                imgThumb={require("../../assets/Portfolio/thumbnails/5.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/6.png")}
                text="UI/UX Concept"
                imgThumb={require("../../assets/Portfolio/thumbnails/6.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/7.jpg")}
                text="Hand Drawn Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/7.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/8.png")}
                text="UI/UX Concept"
                imgThumb={require("../../assets/Portfolio/thumbnails/8.jpg")}
              />
            </div>
            <div className="col-md">
              <PortfolioItem
                src={require("../../assets/Portfolio/9.png")}
                text="UI Concept"
                buttonText="View Project"
                href="https://www.behance.net/gallery/96790015/Mac-OS-Concept"
                imgThumb={require("../../assets/Portfolio/thumbnails/9.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/10.png")}
                text="UI/UX Concept"
                imgThumb={require("../../assets/Portfolio/thumbnails/10.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/11.jpg")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/11.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/12.jpg")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/12.jpg")}
              />
            </div>
            <div className="col-md">
              <PortfolioItem
                src={require("../../assets/Portfolio/13.jpg")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/13.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/14.jpg")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/14.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/15.png")}
                text="Illustration | Art"
                imgThumb={require("../../assets/Portfolio/thumbnails/15.jpg")}
              />
              <PortfolioItem
                src={require("../../assets/Portfolio/16.png")}
                text="UI/UX Concept"
                imgThumb={require("../../assets/Portfolio/thumbnails/16.jpg")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Portfolio;
