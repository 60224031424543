import React from "react";
import Routes from "./Routes";
import Footer from "./components/global/Footer/Footer";
import MyNavbar from "./components/global/MyNavbar/MyNavbar";

function App() {
  return (
    <div className="App">
      <MyNavbar />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
