import React, { Component } from "react";
import "./FButton.css";

class FButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    var tg = "";
    if (this.props.newTab == true) {
      tg = "_blank";
    }
    var classnames = "button" + " " + this.props.classnames;

    return (
      <a href={this.props.href} target={tg} className={classnames}>
        {this.props.value} <span className="button-arrow">&gt;</span>
      </a>
    );
  }
}

export default FButton;
