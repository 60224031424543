import React, { Component } from "react";
import "./PortfolioItem.css";
import FButton from "../FButton/FButton";

class PortfolioItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    //links to img in new tab if not other link defined
    var btnhref = "";
    if (this.props.href === undefined) {
      btnhref = this.props.src;
    } else {
      btnhref = this.props.href;
    }

    //Returns 'View Large' if no text defined
    var btntext = "";
    if (this.props.buttonText === undefined) {
      btntext = "View Large";
    } else {
      btntext = this.props.buttonText;
    }

    return (
      <div className="portfolio-item position-relative">
        <div class="portfolio-overlay w-100 h-100 position-absolute">
          <div class="ml-1 portfolio-text position-absolute">
            <h4 class="portfolio-title">{this.props.text}</h4>
            <div className="mb-3">
              <FButton value={btntext} href={btnhref} newTab={true} />
            </div>
          </div>
        </div>
        <img className="w-100 portfolio-img" src={this.props.imgThumb} />
      </div>
    );
  }
}

export default PortfolioItem;
