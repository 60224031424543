import React, { Component } from "react";
import "./OurServices.css";
import Lottie from "react-lottie";
import { Controller, Scene } from "react-scrollmagic";
import custom from "../../assets/OurServices/animations/custom.json";
import identity from "../../assets/OurServices/animations/identity.json";
import trendy from "../../assets/OurServices/animations/trendy.json";
import professional from "../../assets/OurServices/animations/professional.json";
import friendly from "../../assets/OurServices/animations/friendly.json";
import OnScrollReveal from "../../components/global/Animations/OnScrollReveal";
import FButtonPill from "../../components/FButtonPill/FButtonPill";
import ServicesCarousel from "../../components/ServicesCarousel/ServicesCarousel";

class OurServices extends Component {
  constructor(props) {
    super(props);
    document.documentElement.setAttribute("data-theme", "dark");
  }

  componentDidMount() {
    //this.updateWindowDimensions();
    //window.addEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    //for ae animations
    const defaultAnimationOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const background1 = <div className="position-fixed services-bg-1"></div>;
    const background2 = <div className="position-fixed services-bg-2"></div>;
    const ChangeBg = (props) => {
      const progress = props.progress;
      if (progress < 0.48) return background1;
      else return background2;
    };

    return (
      <div className="overflow-x-hidden">
        <Controller>
          <Scene duration="1100%" triggerHook={0.5}>
            {(progress) => (
              <div>
                <div className="position-fixed services-bg-2"></div>
                <ChangeBg progress={progress}></ChangeBg>
                <div className="services-header">
                  <div className="m-0 row justify-content-center h-100 align-items-center">
                    <div className="services-video position-absolute">
                      <video
                        playsinline="playsinline"
                        autoplay="autoplay"
                        muted="muted"
                        loop="true"
                        id="bgvid"
                        fullscreen="false"
                      >
                        <source
                          src={require("../../assets/OurServices/timelapse.mp4")}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="text-center">
                      <h2 className="f-subtitle">Breathing life into</h2>
                      <h1 className="f-header">Art at Flavus</h1>
                      <div className="row justify-content-center ">
                        <p className="col-7">
                          Our design team is reinventing the design game, using
                          the latest media trends and tips to help better our
                          services for you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="services-all-services padding-t-70">
                  <div className="text-center ">
                    <h2 className="f-title">
                      Let's take a look at
                      <br />
                      what we offer.
                    </h2>
                  </div>
                  <div className="container margin-t-70">
                    <div className="row justify-content-center">
                      <OnScrollReveal
                        y={200}
                        threshold={0}
                        duration={2}
                        opacity={1}
                        repeat={false}
                        css="services-card col-11 col-md-10 padding-t-120 padding-b-120"
                      >
                        <div className="text-center">
                          <h3 className="f-subtitle">
                            Sketch, Paint, Vector, Create
                          </h3>
                          <h2 className="f-title">
                            The finest
                            <br />
                            illustrations
                          </h2>
                          <div className="row justify-content-center mt-4">
                            <p className="col-md-7">
                              Our extensive set of tech and software gives us
                              new ways to customize, reach above and beyond with
                              illustrations. Whether it be for the web, print,
                              or to decor your wall, Flavus has you covered.
                            </p>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                          <img
                            className="col-10 col-md-7 h-100"
                            src={require("../../assets/OurServices/illustration.png")}
                          />
                        </div>
                      </OnScrollReveal>
                    </div>
                    <div className="row justify-content-center margin-t-70">
                      <OnScrollReveal
                        y={200}
                        threshold={0}
                        duration={2}
                        opacity={1}
                        repeat={false}
                        css="services-card col-11 col-md-10 padding-t-120 padding-b-120"
                      >
                        <div className="text-center">
                          <h3 className="f-subtitle">
                            Let's brand the right way
                          </h3>
                          <h2 className="f-title">
                            Creating your
                            <br />
                            own logos
                          </h2>
                          <div className="row justify-content-center mt-4">
                            <p className="col-md-7">
                              Why not get a new sleek and elegant new logo as
                              the face of your business? Leave it to us to help
                              give new a brand new logo.
                            </p>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                          <img
                            className="col-11 col-md-5 h-100"
                            src={require("../../assets/OurServices/logo.png")}
                          />
                          <div className="col-md-4 mt-4 mt-md-0">
                            <div className="text-center text-md-left">
                              <div className="row justify-content-center justify-content-md-start">
                                <div className="">
                                  <Lottie
                                    options={{
                                      ...defaultAnimationOptions,
                                      ...{ animationData: custom },
                                    }}
                                    height={120}
                                    width={120}
                                  />
                                </div>
                              </div>
                              <h4 className="logo-tag-title">
                                Custom Tailored
                              </h4>
                              <p className="logo-tag-desc">
                                Your logo will be tailored towards the brand of
                                your company, we’ll make the logo talk for
                                itself.
                              </p>
                            </div>
                            <div className="text-center text-md-left">
                              <div className="row justify-content-center justify-content-md-start">
                                <div className="pl-2">
                                  <Lottie
                                    options={{
                                      ...defaultAnimationOptions,
                                      ...{ animationData: identity },
                                    }}
                                    height={120}
                                    width={120}
                                  />
                                </div>
                              </div>
                              <h4 className="logo-tag-title">
                                Identity is key
                              </h4>
                              <p className="logo-tag-desc">
                                Making a logo is an important feat to accomplish
                                when starting a business. Our experiences have
                                led us to make some terrific logos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </OnScrollReveal>
                    </div>
                    <div className="row justify-content-center margin-t-70">
                      <OnScrollReveal
                        y={200}
                        threshold={0}
                        duration={2}
                        opacity={1}
                        repeat={false}
                        css="services-card ui-card col-11 col-md-10 padding-t-120 padding-b-120"
                      >
                        <div className="text-center">
                          <h3 className="f-subtitle">Keeping up with UI/UX</h3>
                          <h2 className="f-title">
                            Designing your
                            <br />
                            next website/app
                          </h2>
                          <div className="row justify-content-center mt-4">
                            <p className="col-md-7">
                              Why not get a new sleek and elegant new logo as
                              the face of your business? Leave it to us to help
                              give new a brand new logo.
                            </p>
                          </div>
                        </div>
                        <div className="ui-tag-div row justify-content-center text-center">
                          <div className="col-md-3">
                            <div className="">
                              <Lottie
                                options={{
                                  ...defaultAnimationOptions,
                                  ...{ animationData: trendy },
                                }}
                                height={100}
                                width={100}
                              />
                            </div>
                            <h4 className="ui-tag mt-4">Trendy</h4>
                          </div>
                          <div className="col-md-3">
                            <div className="">
                              <Lottie
                                options={{
                                  ...defaultAnimationOptions,
                                  ...{ animationData: professional },
                                }}
                                height={100}
                                width={100}
                              />
                            </div>
                            <h4 className="ui-tag mt-4">Professional</h4>
                          </div>
                          <div className="col-md-3">
                            <div className="">
                              <Lottie
                                options={{
                                  ...defaultAnimationOptions,
                                  ...{ animationData: friendly },
                                }}
                                height={100}
                                width={100}
                              />
                            </div>
                            <h4 className="ui-tag mt-4">Friendly</h4>
                          </div>
                        </div>
                      </OnScrollReveal>
                    </div>
                    <div className="text-center margin-t-120">
                      <h2 className="f-title">
                        Always willing
                        <br />
                        to provide
                      </h2>
                      <div className="row justify-content-center mt-4">
                        <p className="col-md-6">
                          We’re determined to provide you with all of your media
                          needs. Our wild ideas always seem to turn into crafty
                          pieces of art. Wanna see how? Let’s get in touch to
                          get started.
                        </p>
                      </div>
                      <div className="row justify-content-center mt-3">
                        <FButtonPill
                          value="Contact Us"
                          style="light"
                          href="contact"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center margin-t-120">
                      <OnScrollReveal
                        y={200}
                        threshold={0}
                        duration={2}
                        opacity={1}
                        repeat={false}
                        css="services-card services-card-white col-11 col-md-10 padding-t-120 padding-b-70"
                      >
                        <div className="text-center">
                          <h3 className="f-subtitle">
                            Branding just got a whole lot easier
                          </h3>
                          <h2 className="f-title">
                            Branding with
                            <br />
                            Flavus Media
                          </h2>
                          <div className="row justify-content-center mt-4">
                            <p className="col-md-7">
                              We will make you a brand that is catchy and leaves
                              a trace. Your target group will never forget you.
                            </p>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                          <img
                            className="col-10 col-md-8 h-100"
                            src={require("../../assets/OurServices/branding.png")}
                          />
                        </div>
                      </OnScrollReveal>
                    </div>
                    <div className="row justify-content-center margin-t-70">
                      <OnScrollReveal
                        y={200}
                        threshold={0}
                        duration={2}
                        opacity={1}
                        repeat={false}
                        css="services-card services-card-white photography-card col-11 col-md-10 padding-t-120 padding-b-70"
                      >
                        <div className="text-center photography-card-div">
                          <h3 className="f-subtitle">
                            Capturing life's greatest moments
                          </h3>
                          <h2 className="f-title">
                            Photography for
                            <br />
                            any occassion
                          </h2>
                          <div className="row justify-content-center mt-4">
                            <p className="col-md-8">
                              Editing wedding photos, birthdays, holidays, or
                              family trips. We know it all, and we’ll make all
                              your captured moments worth putting up in a
                              gallery.
                            </p>
                          </div>
                        </div>
                      </OnScrollReveal>
                    </div>
                    <div className="row justify-content-center margin-t-70">
                      <OnScrollReveal
                        y={200}
                        threshold={0}
                        duration={2}
                        opacity={1}
                        repeat={false}
                        css="services-card services-card-white videography-card col-11 col-md-10 padding-t-120 padding-b-70"
                      >
                        <div className="text-center videography-card-div">
                          <h3 className="f-subtitle">
                            Capturing life's greatest moments
                          </h3>
                          <h2 className="f-title">
                            Videography for
                            <br />
                            any occassion
                          </h2>
                          <div className="row justify-content-center mt-4">
                            <p className="col-md-7">
                              Editing wedding photos, birthdays, holidays, or
                              family trips. We know it all, and we’ll make all
                              your captured moments worth putting up in a
                              gallery.
                            </p>
                          </div>
                        </div>
                      </OnScrollReveal>
                    </div>
                    <div className="text-center margin-t-120">
                      <h3 className="f-subtitle">More from Flavus Media</h3>
                      <h2 className="f-title">
                        Always willing
                        <br />
                        to provide
                      </h2>
                    </div>
                  </div>
                </div>
                <ServicesCarousel />
              </div>
            )}
          </Scene>
        </Controller>
      </div>
    );
  }
}

export default OurServices;
