import React from "react";
import { Reveal, Tween } from "react-gsap";

//accepts parameters x, y, duration
const OnScrollReveal = ({ x = 0, y = 0, duration = 0, children, repeat = true, threshold = 0.4, rotate = 0, opacity = 0, css = "" }) => {
  return (
    <Reveal repeat={repeat} threshold={threshold}>
      <Tween from={{ opacity: opacity, x: x, y: y, rotate: rotate }} duration={duration}>
        <div className={css}>{children}</div>
      </Tween>
    </Reveal>
  );
};

export default OnScrollReveal;
