import React, { Component } from "react";
import "./ServicesCarouselItem.css";

class ServicesCarouselItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={this.props.className + " services-carousel-item"}>
        <div className="services-carousel-item-img-div w-100 ">
          <img className="w-100" src={this.props.src} />
        </div>
        <div className="carousel-item-text ml-4 mt-4 mr-4">
          <h4 className="">{this.props.title}</h4>
          <h5 className="mt-3">{this.props.tag}</h5>
          <p className="mt-1 pb-3">{this.props.desc}</p>
        </div>
      </div>
    );
  }
}

export default ServicesCarouselItem;
