import React, { Component } from "react";
import "./Footer.css";
import logo from "../../../assets/logo.svg";
import logoDark from "../../../assets/logo-dark.svg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";

//Adding Icons to Lib
library.add(fab);

class Footer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="footer">
        <div className="container text-center text-md-left footer-content">
          <div className="row justify-content-between mb-md-5 mb-3">
            <div className="col-md-4">
              <div className="footer-logo-light">
                <img src={logo} alt="Flavus Logo" className="footer-logo" />
              </div>
              <div className="footer-logo-dark">
                <img src={logoDark} alt="Flavus Logo" className="footer-logo" />
              </div>
              <h5 className="footer-slogan">
                Creative with
                <br />
                Passion
              </h5>
            </div>
            <div className="col-md-5 mt-md-0 mt-4 mx-0 row justify-content-center justify-content-md-between">
              <div className="col-md-auto">
                <h5 class="footer-title">Discover</h5>

                <ul class="list-unstyled footer-links">
                  <li>
                    <LinkContainer to="/portfolio">
                      <a href="">Our Portfolio</a>
                    </LinkContainer>
                  </li>
                  <li>
                    <LinkContainer to="/services">
                      <a href="">Our Services</a>
                    </LinkContainer>
                  </li>
                </ul>
              </div>

              <div className="col-md-auto">
                <h5 class="footer-title">Company</h5>

                <ul class="list-unstyled footer-links">
                  <li>
                    <LinkContainer to="/about">
                      <a href="">About Us</a>
                    </LinkContainer>
                  </li>
                  <li>
                    <a href="/about#ffp">Opportunities</a>
                  </li>
                </ul>
              </div>

              <div className="col-md-auto">
                <h5 class="footer-title">Reach Out</h5>

                <ul class="list-unstyled footer-links">
                  <li>
                    <LinkContainer to="/contact">
                      <a href="">Contact Us</a>
                    </LinkContainer>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="footer-line" />
          <div className="row justify-content-center justify-content-md-between mt-md-5 mt-4">
            <p className="col-md-8 col-9">
              Designed & Developed by Flavus Media Inc.
            </p>
            <div className="col-md-2 col-6 row justify-content-between justify-content-md-between mr-md-0 mt-3 mt-md-auto">
              <a href="https://www.instagram.com/flavus.ca/" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
              <a href="https://www.facebook.com/flavus.ca/" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "facebook-f"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/flavusmedia/"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={["fab", "linkedin-in"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
              <a href="https://www.behance.net/dwarkeshamin" target="_blank">
                <FontAwesomeIcon
                  icon={["fab", "behance"]}
                  size="md"
                  className="footer-icons"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
