import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AboutUs from "./pages/AboutUs/AboutUs";
import Portfolio from "./pages/Portfolio/Portfolio";
import OurServices from "./pages/OurServices/OurServices";
import Contact from "./pages/Contact/Contact";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/about" exact component={AboutUs} />
      <Route path="/portfolio" exact component={Portfolio} />
      <Route path="/services" exact component={OurServices} />
      <Route path="/contact" exact component={Contact} />
    </Switch>
  );
}
