import React, { Component } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import Lottie from "react-lottie";
import "./AboutUs.css";

import LayerReveal from "../../components/LayerReveal/LayerReveal";
import LayerRevealMobile from "../../components/LayerRevealMobile/LayerRevealMobile";
import FButtonPill from "../../components/FButtonPill/FButtonPill";

import somethingColorful from "../../assets/AboutUs/about-somethingColorful.png";
import mission from "../../assets/AboutUs/mission.png";
import fuel from "../../assets/AboutUs/fuel.png";
import promise from "../../assets/AboutUs/promise.png";
import vibe from "../../assets/AboutUs/vibe.png";

const LayerChange = ({ width }) => {
  console.log(width);

  if (width > 600) {
    return (
      <div className="container margin-t-120 padding-b-120 layer-reveal-div">
        <LayerReveal />
        <div className="left-tag col-2 tag-1 position-absolute">
          <h4 id="trig1" className="f-subtitle semi-bold">
            Forming the Company
          </h4>
          <hr className="mt-0" />
          <div class="tag-dot float-right"></div>
        </div>
        <div className="left-tag col-2 tag-2 position-absolute">
          <h4 id="trig3" className="f-subtitle semi-bold">
            An Idea to Serve
          </h4>
          <hr className="mt-0" />
          <div class="tag-dot float-right"></div>
        </div>

        <div className="row justify-content-end ">
          <div className="left-tag col-2 tag-3 position-absolute ">
            <h4 id="trig2" className="f-subtitle semi-bold text-right">
              Drive to grind
            </h4>
            <hr className="mt-0" />
            <div class="tag-dot float-left"></div>
          </div>
        </div>

        <div className="row justify-content-end ">
          <div className="left-tag col-2 tag-4 position-absolute ">
            <h4 id="trig4" className="f-subtitle semi-bold text-right">
              A passion to design
            </h4>
            <hr className="mt-0" />
            <div class="tag-dot float-left"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container layerMobile-reveal-div">
        <LayerRevealMobile />
      </div>
    );
  }
};

class AboutUs extends Component {
  constructor(props) {
    super(props);
    document.documentElement.setAttribute("data-theme", "light");
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const defaultAnimationOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="overflow-x-hidden overflow-y-hidden">
        <div className="about-landing">
          <div className="row align-items-center h-100">
            <div className="container h-100">
              <div className="row justify-content-between align-items-center h-100">
                <div className="px-5 px-md-0 col-md-5 position-relative order-2 order-md-1 about-landing-text mt-md-0">
                  <h2 className="position-absolute start-quotation">“</h2>
                  <h2 className="f-header">Something colorful</h2>
                  <p>
                    From the very start, Flavus Media stood by passion, vibrancy
                    and life in art.
                  </p>
                </div>
                <div className="col-md-7 order-1 order-md-2">
                  <img
                    src={somethingColorful}
                    className="col something-colorful"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-roots position-relative">
          <div className="text-center margin-t-120">
            <h3 className="f-subtitle">Breaking down the layers</h3>
            <h2 className="f-title">Our Roots</h2>
          </div>

          {/* Shapes */}
          <div className="about-us-shapes">
            <Controller>
              <Scene triggerElement="#trig1" duration={1000}>
                {(progress) => (
                  <Tween
                    to={{
                      x: -50,
                      y: 0,
                    }}
                    ease="none"
                    totalProgress={progress}
                    paused
                  >
                    <div class="circle-2 position-absolute"></div>
                  </Tween>
                )}
              </Scene>
              <Scene triggerElement="#trigger" duration={2650}>
                {(progress) => (
                  <Tween
                    to={{
                      y: 2500,
                      x: 200,
                      rotate: -340,
                    }}
                    ease="none"
                    totalProgress={progress}
                    paused
                  >
                    <div class="triangle-1 position-absolute"></div>
                  </Tween>
                )}
              </Scene>

              <Scene triggerElement="#trig3" duration={2300}>
                {(progress) => (
                  <Tween
                    to={{
                      y: 3000,
                      x: -100,
                      rotate: -340,
                    }}
                    ease="none"
                    totalProgress={progress}
                    paused
                  >
                    <div class="square-2 position-absolute"></div>
                  </Tween>
                )}
              </Scene>
              <Scene triggerElement="#trig2" duration={2400}>
                {(progress) => (
                  <Tween
                    to={{
                      y: 2600,
                      x: -350,
                    }}
                    ease="none"
                    totalProgress={progress}
                    paused
                  >
                    <div class="circle-3 position-absolute"></div>
                  </Tween>
                )}
              </Scene>
              <Scene triggerElement="#trig4" duration={1000}>
                {(progress) => (
                  <div>
                    <Tween
                      to={{
                        x: 0,
                      }}
                      ease="none"
                      totalProgress={progress}
                      paused
                    >
                      <div class="circle-4 position-absolute"></div>
                    </Tween>
                    <Tween
                      to={{
                        y: 700,
                        rotate: 360,
                      }}
                      ease="none"
                      totalProgress={progress}
                      paused
                    >
                      <div class="square-3 position-absolute"></div>
                    </Tween>
                  </div>
                )}
              </Scene>
              <Scene triggerElement="#trig1" duration={2500}>
                {(progress) => (
                  <div>
                    <Tween
                      to={{
                        y: 2650,
                        rotate: 340,
                      }}
                      ease="none"
                      totalProgress={progress}
                      paused
                    >
                      <div class="square-1 position-absolute"></div>
                    </Tween>
                    <Tween
                      to={{
                        y: 2850,
                        x: 140,
                      }}
                      ease="none"
                      totalProgress={progress}
                      paused
                    >
                      <div class="circle-1 position-absolute"></div>
                    </Tween>
                  </div>
                )}
              </Scene>
            </Controller>
          </div>
          <LayerChange width={this.state.width} />
        </div>
        <div className="about-core">
          <div className="text-center margin-t-120">
            <h3 className="f-subtitle">Our Culture</h3>
            <h2 id="trig5" className="f-title">
              The Flavus Core
            </h2>
          </div>
          <div className="container margin-t-70">
            <div className="row justify-content-center">
              <div className="col-md col-10 text-center core-card">
                <div className="row justify-content-center core-icon">
                  <img src={mission} className="col-4 col-md-2" />
                </div>
                <h4 className="f-subtitle bold core-title">Our Mission</h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    We seeks to propel the digital presence of small businesses
                    and strive to enhance customer experience by reimagining
                    brand assets and creating engaging social media campaigns.
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md col-10 text-center core-card mt-5 mt-md-0">
                <div className="row justify-content-center core-icon">
                  <img src={fuel} className="col-4 col-md-2" />
                </div>
                <h4 className="f-subtitle bold core-title">Our Fuel</h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    Our passion for design and seeing our customers reap the
                    fruits of good branding, keeps us going day and night. A cup
                    of coffee does the trick too ;)
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center core-row">
              <div className="col-md col-10 text-center core-card">
                <div className="row justify-content-center core-icon">
                  <img src={promise} className="col-4 col-md-2" />
                </div>
                <h4 className="f-subtitle bold core-title">Our Promise</h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    You can guarantee our delivery of original, quality, and
                    professional work.
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md col-10 text-center core-card mt-5 mt-md-0">
                <div className="row justify-content-center core-icon">
                  <img src={vibe} className="col-4 col-md-2" />
                </div>
                <h4 className="f-subtitle bold core-title">Our Vibe</h4>
                <div className="row justify-content-center core-desc">
                  <p className="col-md-9 col-11">
                    Reimagining a businesses’ brand is hard, but our curiosity,
                    originality, and innovation keeps our work nice and groovy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-team margin-t-120">
          <div className="text-center">
            <h3 className="f-subtitle">A team with grit</h3>
            <h2 className="f-title">The Flavus Five</h2>
          </div>
          <div className="container margin-t-70">
            <div className="row justify-content-md-between justify-content-center">
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/dwarkesh.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Dwarkesh Amin</h4>
                <p className="team-title mt-2">President & Creative Director</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/harsh.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Harsh Patel</h4>
                <p className="team-title mt-2">Head of Technology</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/shiv.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Shiv Patel</h4>
                <p className="team-title mt-2">Head of Operations</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/parin.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Parin Shah</h4>
                <p className="team-title mt-2">Head of Business Development</p>
              </div>
              <div className="col-10 col-md text-center">
                <div className="row justify-content-center">
                  <img
                    src={require("../../assets/AboutUs/team/sharan.png")}
                    className="col-10 team-img"
                  />
                </div>
                <h4 className="team-name mt-4">Sharan Patel</h4>
                <p className="team-title mt-2">Web Developer</p>
              </div>
            </div>
          </div>
          <div className="container ">
            <p className="appendix ml-3">
              <sup>1</sup>Memojis
            </p>
          </div>
          <div className="row justify-content-center mt-4">
            <FButtonPill value="Hire the team" style="dark" href="contact" />
          </div>
        </div>
        <div className="about-ffp margin-t-120 padding-b-120" id="ffp">
          <div className="text-center">
            <h3 className="f-subtitle">Become a winner today and join</h3>
            <h2 className="f-title">
              The Flavus
              <br />
              Freelance Program
            </h2>
            <div className="row justify-content-center">
              <p className="col-10 col-md-5 mt-5">
                The Flavus Freelance Program is the new bridge between
                freelancers and clients! This program will give you access to a
                various design and media related tasks as well as mentorship
                from experienced creatives to help you strengthen your
                portfolio.
              </p>
            </div>
            <p className="mt-2">
              So hit the "Apply Today" button and unlock your creativity!
            </p>
          </div>
          <div className="row justify-content-center mt-5">
            <FButtonPill
              value="Apply Today"
              style="dark"
              href="https://form.jotform.com/flavusmedia/freelance-program"
              newTab={true}
            />
          </div>
        </div>
        <div className="container ">
          <p className="appendix ">
            1. Memojis designed by and property of Apple Inc. No copyright
            intended.
          </p>
        </div>
      </div>
    );
  }
}

export default AboutUs;
